import React from 'react';
import classnames from 'classnames';
import PageTitle from '../../common/PageTitle/PageTitle';
import s from './InformationPage.module.scss';

export default function InformationPage() {
  return (
    <>
      <PageTitle withBackBtn={false} title="Информация" />

      <div className={s.contentBlock}>
        <h3>
          Техническая поддержка системы управления проектной деятельности
          (СУПД), предоставление аналитической информации о реализации:
        </h3>
        <div className={s.list}>
          <span className={s.text}>Начальник отдела аналитической отчетности</span>
          <span className={s.text}>Бабенко Максим Алексеевич</span>
          <a className="external" href={'mailto:babenko.ma@mpcenter.ru'}>
            babenko.ma@mpcenter.ru
          </a>
          <span className={s.text}>
            <a className="external" href={'tel:+79262161962'}>
              +7 (926) 216-19-62
            </a>
          </span>
        </div>
        <div className={s.list}>
          <span className={s.text}>Заместитель начальника Управления мониторинга и аналитической отчетности</span>
          <span className={s.text}>Веденков Денис Витальевич</span>
          <a className="external" href={'mailto:vedenkov.dv@mpcenter.ru'}>
            vedenkov.dv@mpcenter.ru
          </a>
          <span className={s.text}>
            <a className="external" href={'tel:+79151682423'}>
              +7 (915) 168-24-23
            </a>
          </span>
        </div>
      </div>

      <div className={s.contentBlock}>
        <h3>
          Организационно-методическая поддержка в части подготовки отчётности в
          ГИИС "Электронный бюджет":
        </h3>
        <div className={s.list}>
          <span className={s.text}>Ялунин Владимир Сергеевич</span>
          <span className={s.text}>главный специалист</span>
          <a className="external" href={'mailto:yaluninvs@apkpro.ru'}>
            yaluninvs@apkpro.ru
          </a>
          <span className={s.text}>
            <a className="external" href={'tel:+79099492705'}>
              +7 909 949-27-05
            </a>
          </span>
        </div>
      </div>

      <div className={s.contentBlock}>
        <h3>Координаторы мероприятий в модуле "Дорожные карты":</h3>
        <table className={classnames(s.table, s.table__bordered)}>
          <tr>
            <th>Наименование направления</th>
            <th>Координаторы проекта</th>
          </tr>
          <tr>
            <td>"Точка роста"</td>
            <td>
              <div>Федеральный оператор - Центр Просветительских Инициатив</div>
              <div>Брежнев Дмитрий Сергеевич</div>
              <a className="external" href="tel:+79227849299">
                +7 (922) 784-92-99
              </a>
              ,
              <a className="external" href="mailto:brezhnev.ds@mpcenter.ru">
                brezhnev.ds@mpcenter.ru
              </a>
              <div>Гаврилова Дарья Дмитриевна</div>
              <a className="external" href="tel:+79534861533">
                +7 (953) 486-15-33
              </a>
              ,
              <a className="external" href="mailto:gavrilova.dd@mpcenter.ru">
                gavrilova.dd@mpcenter.ru
              </a>
            </td>
          </tr>
          <tr>
            <td>"Кванториум"</td>
            <td>
              <div>Федеральный оператор - Центр Просветительских Инициатив</div>
              <div>Горбатова Марина Геннадьевна</div>
              <a className="external" href="tel:+7995150062">
                +7 (995) 150-06-2
              </a>
              ,
              <a className="external" href="mailto:gorbatova.mg@mpcenter.ru">
                gorbatova.mg@mpcenter.ru
              </a>
            </td>
          </tr>
          <tr>
            <td>IT-куб</td>
            <td>
              <div>Федеральный оператор - ФГАНУ «ФИЦТО»</div>
              <div>Ответственный за ДК в СУПД (Центр Просветительских Инициатив):</div>
              <div>Котлярова Юлия Владимировна</div>
              <a className="external" href="tel:+79202020114">
                +7 (920) 202-01-14
              </a>
              ,
              <a className="external" href="mailto:kotlyarova.yv@mpcenter.ru">
                kotlyarova.yv@mpcenter.ru
              </a>
            </td>
          </tr>
          <tr>
            <td>ЦОС</td>
            <td>
              <div>Федеральный оператор - ФГАНУ «ФИЦТО2</div>
              <div>Ответственный за ДК в СУПД (Центр Просветительских Инициатив):</div>
              <div>Котлярова Юлия Владимировна</div>
              <a className="external" href="tel:+79202020114">
                +7 (920) 202-01-14
              </a>
              ,
              <a className="external" href="mailto:kotlyarova.yv@mpcenter.ru">
                kotlyarova.yv@mpcenter.ru
              </a>
            </td>
          </tr>
          <tr>
            <td>ЦОД</td>
            <td>
              <div>Федеральный оператор - Фонд «Талант и Успех»</div>
              <div>Гусев Антон Сергеевич</div>
              <a className="external" href="tel:+79852900479">
                +7 (985) 290-04-79
              </a>
              ,
              <a className="external" href="mailto:gusev.as@talantiuspeh.ru">
                gusev.as@talantiuspeh.ru
              </a>
              <div>Лусникова Мария Игоревна</div>
              <a className="external" href="tel:+79229613015">
                +7 (922) 961-30-15
              </a>
              ,
              <a className="external" href="mailto:lusnikova.mi@talantiuspeh.ru">
                lusnikova.mi@talantiuspeh.ru
              </a>
            </td>
          </tr>
          <tr>
            <td>Целевая модель ДОД (РМЦ)</td>
            <td>
              <div>Федеральный оператор - ФГБУК «ВЦХТ»</div>
              <div>Жадаев Дмитрий Николаевич</div>
              <a className="external" href="tel:+79197063400">
                +7 (919) 706-34-00
              </a>
              ,
              <a className="external" href="mailto:zhadaevdn@vcht.center">
                zhadaevdn@vcht.center
              </a>
            </td>
          </tr>
          <tr>
            <td>Новые места ДОД</td>
            <td>
              <div>Федеральный оператор - ФГБОУ ДО ФЦДО</div>
              <div>Силантьева Юлия Михайловна</div>
              <a className="external" href="tel:+79275500706">
                +7 (927) 550-07-06
              </a>
              ,
              <a className="external" href="mailto:silantyeva@fedcdo.ru">
                silantyeva@fedcdo.ru
              </a>
            </td>
          </tr>
          <tr>
            <td>ЦОПП</td>
            <td>
              <div>Федеральный оператор - ФГБОУ ДПО ИРПО</div>
              <div>Никонова Ирина Максимовна</div>
              <a className="external" href="tel:+79690375875">
                +7 (969) 037-58-75
              </a>
              ,
              <a className="external" href="mailto:i.nikonova@firpo.ru">
                i.nikonova@firpo.ru
              </a>
            </td>
          </tr>
          <tr>
            <td>Дети с ОВЗ</td>
            <td>
              <div>Федеральный оператор - ФГБНУ «ИКП РАО»</div>
              <div>Сухова Екатерина Михайловна</div>
              <a className="external" href="tel:+79779632971">
                +7 (977) 963-29-71
              </a>
              ,
              <a className="external" href="mailto:dobroshkola@ikp.email">
                dobroshkola@ikp.email
              </a>
            </td>
          </tr>
          <tr>
            <td>Создание в ОО, расположенных в сельской местности и малых городах, условий для занятия ФК и С</td>
            <td>
              <div>Федеральный оператор - ФГБУ «ФЦОМОФВ»</div>
              <div>Богдан Александрович Скрынников</div>
              <a className="external" href="tel:+79670747632">
                +7 (967) 074-76-32
              </a>
              ,
              <a className="external" href="mailto:b.skrynnikov@fcomofv.ru">
                b.skrynnikov@fcomofv.ru
              </a>
            </td>
          </tr>
        </table>
      </div>

      <div className={s.contentBlock}>
        <h3>Кураторы региональных ведомственных проектных офисов:</h3>
        <table className={s.table}>
          <tr>
            <td colSpan={2}>
              <div>Уральская Евгения Владимировна, начальник Управления регионального сопровождения проектной деятельности</div>
              <a className="external" href="tel:+79825125690">
                +7 (982) 512-56-90
              </a>
              ,
              <a className="external" href="mailto:uralskaya.ev@mpcenter.ru">
                uralskaya.ev@mpcenter.ru
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <b>СКФО</b>
              <div>Архипов Александр Петрович</div>
              <a className="external" href="tel:+79178991676">
                +7 (917) 899-16-76
              </a>
              ,
              <a className="external" href="mailto:arhipov.ap@mpcenter.ru">
                arhipov.ap@mpcenter.ru
              </a>
            </td>
            <td>
              <b>СЗФО</b>
              <div>Файзуллина Карина Рамилевна</div>
              <a className="external" href="tel:+79172812546">
                +7 (917) 281-25-46
              </a>
              ,
              <a className="external" href="mailto:faizullina.kr@mpcenter.ru">
                faizullina.kr@mpcenter.ru
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <b>ДФО</b>
              <div>Бортников Иван Николаевич</div>
              <a className="external" href="tel:+79196936361">
                8-919-693-63-61
              </a>
              ,
              <a className="external" href="mailto:bortnikov.in@mpcenter.ru">
                bortnikov.in@mpcenter.ru
              </a>
            </td>
            <td>
              <b>ЦФО</b>
              <div>Тюшина Юлия Сергеевна</div>
              <a className="external" href="tel:+79966165502">
                8(996) 616-55-02
              </a>
              ,
              <a className="external" href="mailto:tyushina.ys@mpcenter.ru">
                tyushina.ys@mpcenter.ru
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <b>ПФО</b>
              <div>Архипов Александр Петрович</div>
              <a className="external" href="tel:+79178991676">
                8(917) 899-16-76
              </a>
              ,
              <a className="external" href="mailto:arhipov.ap@mpcenter.ru">
                arhipov.ap@mpcenter.ru
              </a>
              <div>Горбатова Марина Геннадьевна</div>
              <a className="external" href="tel:+79951500621">
                8(995)150-06-21
              </a>
              ,
              <a className="external" href="mailto:gorbatova.mg@mpcenter.ru">
                gorbatova.mg@mpcenter.ru
              </a>
            </td>
            <td>
              <b>СФО</b>
              <div>Бортников Иван Николаевич</div>
              <a className="external" href="tel:+79196936361">
                8-919-693-63-61
              </a>
              ,
              <a className="external" href="mailto:bortnikov.in@mpcenter.ru">
                bortnikov.in@mpcenter.ru
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <b>УФО</b>
              <div>Брежнев Дмитрий Сергеевич</div>
              <a className="external" href="tel:+79227849299">
                8(922) 784-92-99
              </a>
              ,
              <a className="external" href="mailto:brezhnev.ds@mpcenter.ru">
                brezhnev.ds@mpcenter.ru
              </a>
            </td>
            <td>
              <b>ЮФО</b>
              <div>Файзуллина Карина Рамилевна</div>
              <a className="external" href="tel:+79172812546">
                +7 (917) 281-25-46
              </a>
              ,
              <a className="external" href="mailto:faizullina.kr@mpcenter.ru">
                faizullina.kr@mpcenter.ru
              </a>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <b>ДНР, ЛНР, Запорожская и Херсонская области</b>
              <div>Брежнев Дмитрий Сергеевич</div>
              <a className="external" href="tel:+79227849299">
                8(922) 784-92-99
              </a>
              ,
              <a className="external" href="mailto:brezhnev.ds@mpcenter.ru">
                brezhnev.ds@mpcenter.ru
              </a>
            </td>
          </tr>
        </table>
      </div>

      <div className={s.contentBlock}>
        <h3>
          Поддержка в части создания имущественного комплекса Нацпроекта
          "Образование", инфраструктурные листы
        </h3>
        <table className={s.table}>
          <tr>
            <td>
              <b>Управление модулями «Закупки» и <br />
              «Управление инфраструктурными листами» в СУПД</b>
              <div>Семенюк Георгий Эдуардович</div>
              <a className="external" href="tel:+79646363355">
                +7 (964) 636-33-55
              </a>
              ,
              <a className="external" href="mailto:semenuk.ge@mpcenter.ru">
                semenuk.ge@mpcenter.ru
              </a>
            </td>
            <td>
              <b>Документооборот по направлению</b>
              <div>Вербицкая Ольга Геннадьевна</div>
              <a className="external" href="tel:+79166020255">
                +7 (916) 602-02-55
              </a>
              ,
              <a className="external" href="mailto:verbitskaya.og@mpcenter.ru">
                verbitskaya.og@mpcenter.ru
              </a>
            </td>
            <td>
              <b>Закрытие контрольных точек по мероприятиям дорожных карт в части создаваемой инфраструктуры</b>
              <div>Кулаева Наталья Вячеславовна</div>
              <a className="external" href="tel:+79252439040">
                +7 (925) 243-90-40
              </a>
              ,
              <a className="external" href="mailto:kulaeva.nv@mpcenter.ru">
                kulaeva.nv@mpcenter.ru
              </a>
            </td>
          </tr>
        </table>
      </div>

      <div className={s.contentBlock}>
        <h3>Рассмотрение инфраструктурных листов:</h3>

        <table className={s.table}>
          <tr>
            <td>
              <b>"Точка роста", Школьный "Кванториум"</b>
              <div>Иванов Алексей Александрович</div>
              <a className="external" href="tel:+79161435841">
                +7 (916) 143-58-41
              </a>
              ,
              <a className="external" href="mailto:ivanov.aa@mpcenter.ru">
                ivanov.aa@mpcenter.ru
              </a>
              <div>Милова Ирина Сергеевна</div>
              <a className="external" href="tel:+79160900267">
                +7 (916) 090-02-67
              </a>
              ,
              <a className="external" href="mailto:milova.is@mpcenter.ru">
                milova.is@mpcenter.ru
              </a>
            </td>
            <td>
              <b>ЦОС, ИТ-куб</b>
              <div>Семенюк Георгий Эдуардович</div>
              <a className="external" href="tel:+79646363355">
                +7 (964) 636-33-55
              </a>
              ,
              <a className="external" href="mailto:semenuk.ge@mpcenter.ru">
                semenuk.ge@mpcenter.ru
              </a>
              <div>Расулов Александр Эдуардович</div>
              <a className="external" href="tel:+79096635403">
                +7 (909) 663-54-03
              </a>
              ,
              <a className="external" href="mailto:rasulovae@mpcenter.ru">
                rasulovae@mpcenter.ru
              </a>
            </td>
          </tr>
        </table>
      </div>

      <ul>
        <li>
          <a
            className="external"
            href="/Инструкция по работе в подсистеме сбора и консолидации данных.pdf"
          >
            Инструкция по работе в подсистеме сбора и консолидации данных
          </a>
        </li>
        <li>
          <a
            className="external"
            href="/Инструкция_по_фотомониторингу_для_РВПО.pdf"
          >
            Инструкция по фотомониторингу для РВПО
          </a>
        </li>
        <li>
          <a className="external" href="/Инструкция_Состав_закупки.pdf">
            Инструкция "Состав закупки"
          </a>
        </li>
        <li>
          <a
            className="external"
            href="/Инструкция_Состав_закупки_Приложение_1.xlsx"
          >
            Инструкция "Состав закупки". Приложение 1
          </a>
        </li>
        <li>
          <a
            className="external"
            href="/РВПО_Инструкция_по_работе_в_подсистеме_по_инфралистам.pdf"
          >
            РВПО Инструкция по работе в подсистеме по инфралистам
          </a>
        </li>
      </ul>
    </>
  );
}
