import React, { useState } from 'react';
import { Route, Switch } from 'react-router';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { createMuiTheme } from '@material-ui/core/styles';
//import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { useParams } from 'react-router-dom';
import { InfoPanel } from '../../common/InfoPanel/InfoPanel';
import { infralistRoutes } from '../../store/infralist/routes/infralistRoutes';
import { useInfralist } from '../../store/infralist/useInfralist';
import { useInfralistVersion } from '../../store/infralist/versions/useInfralistVersion';
import CreateEquipmentPage from '../createEquipment/CreateEquipmentPage';
import { InfralistLayout } from '../DefaultLayout';
import { DirectionCommentsPage } from './comments/direction/DirectionCommentsPage';
import EquipmentCommentsPage from './comments/equipment/EquipmentCommentsPage';
import { SectionCommentsPage } from './comments/section/SectionCommentsPage';
import InfralistDirection from './direction/InfralistDirection';
import InfralistSection from './direction/section/InfralistSection';
import { InfralistChangeLogs } from './change-logs/InfralistChangeLogs';
import InfralistPage from './InfralistPage';
import VersionSection from './versions/direction/section/VersionSection';
import VersionDirection from './versions/direction/VersionDirection';
import InfralistVersionPage from './versions/InfralistVersionPage';
import { VersionsPanel } from './versions/VersionsPanel';
import colors from '../../colors.scss';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: colors.greenBright,
      contrastText: colors.white,
      light: colors.greenLight
    },
    text: {
      primary: colors.graytable
    }
  },
  typography: {
    button: {
      '&.MuiButton-containedSecondary.Mui-disabled': {
        backgroundColor: colors.greenBright,
        opacity: 0.5,
        color: colors.white
      },
      '&.MuiButton-outlinedSecondary.Mui-disabled': {
        opacity: 0.5,
        color: colors.greenBright,
        borderColor: colors.greenBright
      },
      '&.MuiButton-containedPrimary.Mui-disabled': {
        backgroundColor: colors.blue,
        opacity: 0.5,
        color: colors.white
      }
    }
  }
});

const InfralistRoot = () => {
  const { isVersionMode } = useInfralistVersion();
  const [versionsPanelIsVisible, setVersionsPanelVisible] = useState(
    isVersionMode
  );
  const { infralistId } = useParams();
  const infralistQuery = useInfralist(infralistId);

  return (
    <ThemeProvider theme={theme}>
      <InfralistLayout
        versionsPanelIsVisible={versionsPanelIsVisible}
        setVersionsPanelVisible={setVersionsPanelVisible}
      >
        {infralistQuery.state.loaded &&
          !infralistQuery.state.data?.editable && (
            <InfoPanel
              title={
                'Редактирование инфраструктурного листа невозможно из-за его статуса и роли пользователя'
              }
            />
          )}
        <Route
          path={[
            infralistRoutes.SECTION_VERSION,
            infralistRoutes.SECTION,
            infralistRoutes.DIRECTION_VERSION,
            infralistRoutes.DIRECTION,
            infralistRoutes.INFRALIST_VERSION,
            infralistRoutes.INFRALIST
          ]}
        >
          {versionsPanelIsVisible && <VersionsPanel />}
        </Route>

        <Switch>
          <Route
            path={infralistRoutes.INFRALIST_VERSION}
            exact={true}
            component={InfralistVersionPage}
          />
          <Route
            path={infralistRoutes.INFRALIST}
            exact={true}
            component={InfralistPage}
          />
          <Route
            path={infralistRoutes.EQUIPMENT_COMMENT}
            exact={true}
            component={EquipmentCommentsPage}
          />
          <Route
            path={infralistRoutes.DIRECTION_VERSION}
            exact={true}
            component={VersionDirection}
          />
          <Route
            path={infralistRoutes.DIRECTION}
            exact={true}
            component={InfralistDirection}
          />
          <Route
            path={infralistRoutes.DIRECTION_COMMENT}
            exact={true}
            component={DirectionCommentsPage}
          />
          <Route
            path={infralistRoutes.SECTION_VERSION}
            exact={true}
            component={VersionSection}
          />
          <Route
            path={infralistRoutes.SECTION}
            exact={true}
            component={InfralistSection}
          />
          <Route
            path={infralistRoutes.CREATE_EQUIPMENT}
            exact={true}
            component={CreateEquipmentPage}
          />
          <Route
            path={infralistRoutes.SECTION_COMMENT}
            exact={true}
            component={SectionCommentsPage}
          />
          <Route
            path={infralistRoutes.CHANGE_LOGS}
            exact={true}
            component={InfralistChangeLogs}
          />
        </Switch>
      </InfralistLayout>
    </ThemeProvider>
  );
};

export default InfralistRoot;
